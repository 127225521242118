import React, { useState } from "react";
import clsx from "clsx";
import { Text, Container, Input, Fieldset } from "@atoms";
import { FormContainer } from "@molecules";

const ApplicationForm = ({ bgColor, order, copy, form }) => {
  const [formState, setFormState] = useState({});

  const handleSubmit = async e => {
    e.preventDefault();

    // wrap entire handler in error handler
    try {
      const { fields, email, title } = form;

      // rename fields for upload
      const renamedFields = Object.entries(formState).reduce(
        (newForm, [key, value]) => ({
          ...newForm,
          [fields.find(f => f.name === key).label]: value,
        }),
        {}
      );

      // get list of filefields
      const fileKeys = fields
        .filter(field => field.type === "file")
        .map(f => f.label);
      const now = new Date().toISOString();

      // declare upload function
      const uploadFile = async fileKey => {
        // declare empty form
        const newForm = new FormData();
        // add filefiled to form

        newForm.append("name", now + renamedFields[fileKey].name);
        newForm.append("file", renamedFields[fileKey]);

        // upload file and get uploaded file's s3 url
        const res = await fetch("/api/uploadFile", {
          method: "POST",
          body: newForm,
        });

        // resolve response
        const response = await res.json();

        // throw error if status is not 200
        if (!response.url) throw Error("File upload failed. Please try again.");
        // else return response
        return { [fileKey]: response.url };
      };

      // iterate over filefields and get urls
      const urls = await (
        await Promise.all(fileKeys.map(uploadFile))
      ).reduce((a, b) => ({ ...a, ...b }), {});

      // if there are filefields, add them to the
      if (fileKeys.length) {
        fileKeys.forEach(fileField => {
          renamedFields[fileField] = urls[fileField];
        });
      }

      const data = await fetch("/api/submitForm", {
        method: "POST",
        body: JSON.stringify({
          form: renamedFields,
          email,
          title,
        }),
      });
      return await data.json();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return { error: JSON.stringify(error) };
    }
  };

  return (
    <section
      className={clsx("relative z-20 min-h-[100vh] md:min-h-[80vh]", {
        "pt-32 md:pt-48": order === 0,
        "bg-midnight": bgColor === "midnight",
        "bg-white": bgColor === "white",
      })}
    >
      <Container key={form?.uid} variant="xxs">
        <div className="max-w-3xl">
          <Text
            richText
            className={clsx("text-center", {
              "prose-dark prose-p:text-lg lg:prose-p:text-xl":
                bgColor === "midnight",
            })}
          >
            {copy}
          </Text>
        </div>
        <FormContainer
          formState={[formState, setFormState]}
          onSubmit={handleSubmit}
          successMessage="Thank you for your application. We will be in touch with you soon."
          successClass="mx-auto text-center"
          bgColor={bgColor}
          className="flex flex-wrap gap-x-8 gap-y-12 py-16 text-white"
        >
          {form?.fields?.map((field, i) => {
            const { uid } = field;
            return (
              <Fieldset
                key={uid}
                className={clsx("flex w-full", {
                  "md:flex-[1_1_calc(50%-1rem)]": field.type !== "textarea",
                })}
              >
                <Input
                  placeholder={field.type === "select" ? "Select one" : null}
                  inverse
                  key={uid}
                  {...field}
                  className="w-full"
                  color="white"
                  group={
                    field.options && field.type === "radio"
                      ? field.options
                      : null
                  }
                />
              </Fieldset>
            );
          })}
        </FormContainer>
      </Container>
    </section>
  );
};

export default ApplicationForm;
